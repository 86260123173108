import React, { useState } from "react";
import {
  Container,
  Title,
  Text,
  Grid,
  Card,
  Image,
  Group,
  Center,
  Box,
} from "@mantine/core";
import OfficeSide from "../app/assets/img/bta-office-side.png";
import VerticalTable from "../app/assets/img/massage -table-vertical.png";
import Office from "../app/assets/img/office.png";
import HorizontalTable from "../app/assets/img/massage-table-side.png";
import Lobby from "../app/assets/img/bta-lobby.jpg";
import Space from "../app/assets/img/bta-office-photo.jpeg";

const SuitePage = () => {
  const items = [
    { src: OfficeSide, altText: "Office Side", caption: "" },
    { src: VerticalTable, altText: "Vertical Table", caption: "" },
    { src: Office, altText: "Office", caption: "" },
    { src: HorizontalTable, altText: "Horizontal Table", caption: "" },
    { src: Lobby, altText: "Lobby", caption: "" },
    { src: Space, altText: "Office Space", caption: "" },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  // Handle clicking on thumbnail
  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };

  // Navigation functions
  const nextSlide = () => {
    setActiveIndex((current) =>
      current === items.length - 1 ? 0 : current + 1
    );
  };

  const prevSlide = () => {
    setActiveIndex((current) =>
      current === 0 ? items.length - 1 : current - 1
    );
  };

  // Updated getImageScale function to make the last image larger
  const getImageScale = (index) => {
    if (index === 3) return 0.9; // Make image 4 (HorizontalTable) smaller
    if (index < 3) return 1.25; // First 3 images are larger (125% scale)
    if (index === 5) return 2.5; // Make the last image (Office Space) larger
    return 1.25; // Normal scale for the rest
  };

  return (
    <>
      <Container size="lg" py="xl" style={{ fontFamily: "nunito" }}>
        <Grid>
          <Grid.Col>
            <Title order={2} align="left" mb="md">
              Welcome to Body Therapy Arts in Encinitas… Your Neighborhood
              Resource for Wellness!
            </Title>
            <Text align="left" mb="xl">
              We're conveniently located in the heart of Village Park in
              beautiful Encinitas, providing integrative healthcare services to
              the surrounding communities of Encinitas, Cardiff by the Sea,
              Leucadia, Carlsbad, Solana Beach, Rancho Santa Fe, Del Mar and all
              of North San Diego County since 1998.
            </Text>
          </Grid.Col>
        </Grid>

        <Grid justify="left">
          <Grid.Col md={10} lg={8}>
            <Card shadow="sm" p="md" radius="md" withBorder>
              <Card.Section>
                <Box
                  style={{
                    position: "relative",
                    height: 450, // Fixed height for all images
                    overflow: "hidden", // Mask overflow
                  }}
                >
                  {/* Container with fixed dimensions */}
                  <Box
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                    }}
                  >
                    {/* Image with dynamic scale */}
                    <Box
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        transform: `scale(${getImageScale(activeIndex)})`,
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <Image
                        src={items[activeIndex].src}
                        alt={items[activeIndex].altText}
                        height="auto"
                        width="100%"
                        fit="contain"
                      />
                    </Box>
                  </Box>

                  {/* Navigation controls - now larger and better positioned */}
                  <Group
                    position="apart"
                    style={{
                      position: "absolute",
                      bottom: 20,
                      left: 0,
                      right: 0,
                      paddingLeft: 40,
                      paddingRight: 40,
                    }}
                  >
                    <Box
                      style={{
                        cursor: "pointer",
                        background: "rgba(0,0,0,0.5)",
                        color: "white",
                        width: 60,
                        height: 60,
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: 36,
                        fontWeight: "bold",
                        transition: "background 0.2s ease",
                        "&:hover": {
                          background: "rgba(0,0,0,0.7)",
                        },
                      }}
                      onClick={prevSlide}
                    >
                      ‹
                    </Box>
                    <Box
                      style={{
                        cursor: "pointer",
                        background: "rgba(0,0,0,0.5)",
                        color: "white",
                        width: 60,
                        height: 60,
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: 36,
                        fontWeight: "bold",
                        transition: "background 0.2s ease",
                        "&:hover": {
                          background: "rgba(0,0,0,0.7)",
                        },
                      }}
                      onClick={nextSlide}
                    >
                      ›
                    </Box>
                  </Group>
                </Box>
              </Card.Section>
            </Card>

            <Center mt="md">
              <Group position="center" spacing="xs">
                {items.map((item, index) => (
                  <Card
                    key={index}
                    p="xs"
                    shadow="sm"
                    radius="md"
                    withBorder
                    style={{
                      cursor: "pointer",
                      border:
                        index === activeIndex ? "2px solid #228be6" : undefined,
                      width: 80,
                      height: 60,
                      opacity: index === activeIndex ? 1 : 0.7,
                      transition: "all 0.2s ease",
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                    onClick={() => handleThumbnailClick(index)}
                  >
                    <Image
                      src={item.src}
                      alt={item.altText}
                      height={50}
                      width={70}
                      fit="cover"
                    />
                  </Card>
                ))}
              </Group>
            </Center>
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};

export default SuitePage;
