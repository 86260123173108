// ServicesPage.jsx
import React from "react";
import { Container, Title, Text, Box, Space } from "@mantine/core";
import ServicesList from "../app/shared/features/services/ServicesList.jsx";
import { SERVICES } from "../app/shared/features/services/SERVICES.js";

const ServicesPage = () => {
  const initialServices = SERVICES.slice(0, 6);

  return (
    <Container size="lg">
      <Box py="xl">
        <Title
          order={1}
          align="center"
          mb="xl"
          style={{ fontFamily: "nunito, sans-serif" }}
        >
          Our Services
        </Title>
        <Text size="lg" align="left" mb="xs">
          We are here to help YOU feel your best! As independent and caring
          practitioners, we offer Massage Therapy for pain relief and
          relaxation, Pregnancy Massage, Deep Tissue Massage, Structural
          Integration, Neuro-Muscular Therapy, Acupuncture, Fertility
          Acupuncture and Energy Healing in a peaceful and healing environment.
        </Text>
        <Space h="xl" />
        <ServicesList services={initialServices} />
      </Box>
    </Container>
  );
};

export default ServicesPage;
