import Header from "./components/Header";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import PractitionerPage from "./pages/PractitionerPage";
import SuitePage from "./pages/SuitePage";
import ServicesPage from "./pages/ServicesPage";
import { MantineProvider, createTheme } from "@mantine/core";
import "./App.css";

const theme = createTheme({
  primaryColor: "blue",
  // Add more custom theme configurations
});

function App() {
  return (
    <MantineProvider>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="practitioners" element={<PractitionerPage />} />
          <Route path="suite" element={<SuitePage />} />
          <Route path="services" element={<ServicesPage />} />
        </Routes>
        <Footer />
      </div>
    </MantineProvider>
  );
}

export default App;
