import {
  Box,
  Container,
  Text,
  Anchor,
  Flex,
  Group,
  Stack,
} from "@mantine/core";
import { MapPin } from "lucide-react";
import year from "../utils/currentYear.js";
import EmailContact from "./Contact Info/EmailContact.tsx";
import PhoneContact from "./Contact Info/PhoneContact.tsx";
import { MAP_URL, BUSINESS_ADDRESS } from "../utils/mapinfo.ts";

import logoImage from "../../src/app/assets/img/btafavicon.png"; // Adjust this path to where your image is located

const Footer = () => {
  const mapUrl =
    "https://www.google.com/maps/dir//1991+Village+Park+Way,+Encinitas,+CA+92024/@33.053135,-117.3280127,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x80dc0b95bd8b1c0f:0xd2d309cdd42fa7b!2m2!1d-117.2456115!2d33.0531621?entry=ttu";

  const embossedLogoStyle = {
    position: "relative",
    display: "inline-block",
    filter: "drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 2px white)",
  };

  return (
    <Box
      component="footer"
      py="md"
      className="site-footer"
      style={{
        background: `radial-gradient(
          ellipse at top center,
          var(--yellowest-sunset) 0%,
          var(--less-yellow-sunset) 30%,
          var(--pink-yellow-sunset) 60%,
          var(--less-pink-sunset) 100%
        )`,
        position: "relative",
      }}
    >
      <Container size="lg" style={{ position: "relative" }}>
        {/* Logo for sm screens and up - positioned absolutely */}
        <Box
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
          visibleFrom="sm"
        >
          <div style={embossedLogoStyle}>
            <img
              src={logoImage}
              alt="Body Therapy Arts Favicon"
              style={{
                height: "90%",
                maxHeight: "150px",
                objectFit: "contain",
              }}
              onError={(e) => {
                console.error("Image failed to load:", e);
                console.log("Attempted image path:", logoImage);
              }}
            />
          </div>
        </Box>

        {/* Center content section */}
        <Flex
          direction="column"
          align="center"
          justify="center"
          style={{
            width: { base: "100%", sm: "80%" },
            margin: "0 auto",
          }}
        >
          <Stack gap="xs" align="center" mb="lg">
            <Box>
              <Group justify="center">
                <PhoneContact />
                <EmailContact />
              </Group>
            </Box>

            <Anchor
              href={MAP_URL}
              target="_blank"
              rel="noopener noreferrer"
              underline="hover"
              size="lg"
              styles={(theme) => ({
                root: {
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  textAlign: "center",
                },
                label: {
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                },
              })}
            >
              <MapPin size={16} />
              <span>{BUSINESS_ADDRESS}</span>
            </Anchor>
          </Stack>

          {/* Mobile logo stacked below contact info (only visible on xs screens) */}
          <Box
            hiddenFrom="sm"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <div style={embossedLogoStyle}>
              <img
                src={logoImage}
                alt="Body Therapy Arts Favicon"
                style={{
                  width: "75px",
                  height: "75px",
                  objectFit: "contain",
                }}
                onError={(e) =>
                  console.error("Mobile image failed to load:", e)
                }
              />
            </div>
          </Box>

          <Box>
            <Text ta="center" size="sm" c="gray.8">
              Copyright © {year} All Rights Reserved | Body Therapy Arts
            </Text>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
