import { Card, Title, Text, Image, Grid, Container } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const ServiceCard = ({ service }) => {
  const { image, about, type } = service;
  const isSmallScreen = useMediaQuery("(max-width: 768px)"); // Adjust breakpoint as needed

  return (
    <Container>
      <Grid>
        <Grid.Col>
          <Card
            shadow="sm"
            p="lg"
            radius="md"
            style={{
              backgroundColor: "#f2f2f2",
              padding: "1rem",
              borderRadius: "8px",
            }}
          >
            <Title
              order={2}
              mb="md"
              className="card-title"
              style={{ fontFamily: "nunito, sans-serif" }}
            >
              {type}
            </Title>
            <div
              style={{
                backgroundColor: "#d9d9d9",
                padding: "1rem",
                borderRadius: "8px",
              }}
            >
              <Grid gutter="md" align="center">
                {isSmallScreen ? (
                  <>
                    <Grid.Col span={12}>
                      <Image className="people-img" src={image} alt={type} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Text>{about}</Text>
                    </Grid.Col>
                  </>
                ) : (
                  <>
                    <Grid.Col span={3}>
                      <Image className="people-img" src={image} alt={type} />
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <Text>{about}</Text>
                    </Grid.Col>
                  </>
                )}
              </Grid>
            </div>
          </Card>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default ServiceCard;
