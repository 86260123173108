import { useState } from "react";
import {
  Group,
  Anchor,
  CopyButton,
  Tooltip,
  ActionIcon,
  Box,
} from "@mantine/core";
import { Copy, Mail, BookOpen, BookMarked } from "lucide-react";

interface EmailContactProps {
  email?: string;
  showToggle?: boolean;
}

const EmailContact = ({
  email = "info@bodytherapyarts.com",
  showToggle = true,
}: EmailContactProps) => {
  const [isEmailVisible, setEmailVisible] = useState(false);

  const toggleEmailVisibility = () => {
    setEmailVisible((prev) => !prev);
  };

  return (
    <Group gap={4} align="center">
      <Anchor
        href={`mailto:${email}`}
        underline="hover"
        size="lg"
        styles={(theme) => ({
          root: {
            display: "flex",
            alignItems: "center",
            gap: "8px",
          },
          // This targets only the text inside the anchor, not other elements
          label: {
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          },
        })}
      >
        <Mail size={16} />
        <span>{showToggle && !isEmailVisible ? "Email" : email}</span>
      </Anchor>

      {showToggle && (
        <Tooltip label={isEmailVisible ? "Hide email" : "Show email"}>
          <ActionIcon
            c={isEmailVisible ? "gray.5" : "gray.8"}
            variant="subtle"
            onClick={toggleEmailVisibility}
          >
            {isEmailVisible ? <BookMarked size={18} /> : <BookOpen size={18} />}
          </ActionIcon>
        </Tooltip>
      )}

      {(isEmailVisible || !showToggle) && (
        <CopyButton value={email} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip label={copied ? "Copied!" : "Copy"}>
              <ActionIcon
                color={copied ? "teal" : "gray"}
                variant="subtle"
                onClick={copy}
              >
                <Copy size={18} />
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      )}
    </Group>
  );
};

export default EmailContact;
