import { useState } from "react";
import { Group, Anchor, CopyButton, Tooltip, ActionIcon } from "@mantine/core";
import { Copy, Phone, BookOpen, BookMarked } from "lucide-react";

interface PhoneContactProps {
  phone?: string;
  formattedPhone?: string;
  showToggle?: boolean;
}

const PhoneContact = ({
  phone = "7604158620",
  formattedPhone = "(760) 415-8620",
  showToggle = true,
}: PhoneContactProps) => {
  const [isPhoneVisible, setPhoneVisible] = useState(false);

  const togglePhoneVisibility = () => {
    setPhoneVisible((prev) => !prev);
  };

  return (
    <Group gap={4} align="center">
      <Anchor
        href={`tel:${phone}`}
        underline="hover"
        size="lg"
        styles={(theme) => ({
          root: {
            display: "flex",
            alignItems: "center",
            gap: "8px",
          },
          label: {
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          },
        })}
      >
        <Phone size={16} />
        <span>{showToggle && !isPhoneVisible ? "Phone" : formattedPhone}</span>
      </Anchor>

      {showToggle && (
        <Tooltip label={isPhoneVisible ? "Hide number" : "Show number"}>
          <ActionIcon
            c={isPhoneVisible ? "gray.5" : "gray.8"}
            variant="subtle"
            onClick={togglePhoneVisibility}
          >
            {isPhoneVisible ? <BookMarked size={18} /> : <BookOpen size={18} />}
          </ActionIcon>
        </Tooltip>
      )}

      {(isPhoneVisible || !showToggle) && (
        <CopyButton value={formattedPhone} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip label={copied ? "Copied!" : "Copy"}>
              <ActionIcon
                c={copied ? "teal" : isPhoneVisible ? "gray.8" : "gray.5"}
                variant="subtle"
                onClick={copy}
              >
                <Copy size={18} />
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      )}
    </Group>
  );
};

export default PhoneContact;
