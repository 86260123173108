// ServicesList.jsx
import { Stack } from "@mantine/core";
import ServiceCard from "./ServiceCard.jsx";

const ServicesList = ({ services }) => {
  return (
    <Stack spacing="xl">
      {services.map((service, index) => (
        <ServiceCard key={service.id} service={service} index={index} />
      ))}
    </Stack>
  );
};

export default ServicesList;
