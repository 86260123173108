import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  Text,
  Title,
  Button,
  Image,
  Box,
} from "@mantine/core";

import Space from "../app/assets/img/mindful.png";
import Services from "../app/assets/img/massage.jpg";

const HomepageCard = ({ src, to, title }) => {
  // Determine if this is the Space image and apply special styling if so
  const imageStyle =
    title === "The Space"
      ? {
          objectPosition: "center -50px",
        }
      : {};

  return (
    <Grid.Col span={{ base: 12, lg: 6 }}>
      <Card
        component={Link}
        to={to}
        padding={0}
        radius="md"
        withBorder
        style={{ position: "relative", overflow: "hidden" }}
      >
        <Card.Section style={{ position: "relative" }}>
          <Image
            src={src}
            alt={`${title} image`}
            height={250}
            fit="cover"
            style={imageStyle}
          />
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
              backgroundColor: "rgba(0,0,0,0.55)",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
              width: "fit-content",
            }}
          >
            <Title
              order={3}
              style={{
                background: `linear-gradient(
        to bottom, 
        var(--yellowest-sunset) 0%, 
        var(--less-yellow-sunset) 25%, 
        var(--pink-yellow-sunset) 50%, 
        var(--less-pink-sunset) 100%
      )`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                color: "transparent",
                textAlign: "center",
                margin: 0,
              }}
            >
              {title}
            </Title>
          </Box>
        </Card.Section>
      </Card>
    </Grid.Col>
  );
};

const HomePage = () => {
  return (
    <Container size="xl" px="md">
      <Grid>
        <Grid.Col span={12}>
          <Title
            className="intro-header-title"
            order={2}
            mt="xl"
            mb="md"
            style={{ fontSize: "2.5rem", fontFamily: "nunito, sans-serif" }}
          >
            Body Therapy Arts has been offering a range of holistic services to
            Encinitas, CA for over 30 years.
          </Title>

          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            mb="lg"
          >
            <Button
              className="btns contact-info-btn get-in-touch-btn"
              component={Link}
              to="/contact"
              variant="filled"
              size="md"
            >
              Get in touch
            </Button>
          </Box>

          <Text size="lg" mb="xl" style={{ fontFamily: "nunito, sans-serif" }}>
            We are a group of independent healthcare practitioners offering
            massage therapy, acupuncture, naturopathic medicine, hypnotherapy,
            and personalized skincare. Specializing in pregnancy massage, deep
            tissue massage, acupuncture for women's health and fertility,
            acupuncture for musculoskeletal pain, as well as support for weight
            loss through hypnotherapy and natural medicine.
          </Text>
        </Grid.Col>
      </Grid>

      <Grid className="homepage-card-container">
        <HomepageCard src={Space} to="/suite" title="The Space" />
        <HomepageCard src={Services} to="/services" title="Services" />
      </Grid>
    </Container>
  );
};

export default HomePage;
