import { Container, Grid, Text, Anchor, Box, Title, Center, Stack, Group } from "@mantine/core";
import MapComponent from "../components/Map";
import EmailContact from "../components/Contact Info/EmailContact";
import PhoneContact from "../components/Contact Info/PhoneContact";
import { MapPin } from "lucide-react";
import { MAP_URL, BUSINESS_ADDRESS } from "../utils/mapinfo";

const ContactPage = () => {
  return (
    <Container size="xl">
      <Grid>
        <Grid.Col span={12}>
          <Title
            mt="xl"
            order={1}
            align="center"
            style={{ fontFamily: "nunito, sans-serif" }}
          >
            Book an Appointment Today
          </Title>
        </Grid.Col>

        {/* <Grid.Col span={12} md={6}>
          <Text className="specials-text">
            New Client Special: Receive $10.00 off your first session with us!
          </Text>
        </Grid.Col> */}
        <Grid.Col span={12}>
          <Center>
            <Group position="center" spacing="xl">
              <PhoneContact />
              <EmailContact />
            </Group>
          </Center>
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Text>
            We're conveniently located in the heart of Village Park in beautiful
            Encinitas, providing integrative healthcare services to the
            surrounding communities of Encinitas, Cardiff by the Sea, Leucadia,
            Carlsbad, Solana Beach, Rancho Santa Fe, Del Mar and all of North
            San Diego County since 1998.
          </Text>
          <Grid.Col span={12}>
            <Center>
              <Anchor
                href={MAP_URL}
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
                size="lg"
                styles={(theme) => ({
                  root: {
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    textAlign: "center",
                  },
                  label: {
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  },
                })}
              >
                <MapPin size={16} />
                <span>{BUSINESS_ADDRESS}</span>
              </Anchor>
            </Center>
          </Grid.Col>
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col span={0} xl={1}></Grid.Col>
        <Grid.Col span={12} xl={10}>
          <Box style={{ height: "400px", width: "100%" }}>
            <MapComponent />
          </Box>
        </Grid.Col>
        <Grid.Col span={0} xl={1}></Grid.Col>
      </Grid>
    </Container>
  );
};

export default ContactPage;
